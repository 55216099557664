import React from 'react';
import { ActivityIndicator, PlainView } from './Components/SimpleComponents';
import { WaffleOrange } from './Constants/Style';
import { ViewStyle } from 'react-native';

type UpdatingProps = {
  updating: boolean;
  isCompact?: boolean;
  viewStyle?: ViewStyle;
};

export const Updating = ({
  updating,
  viewStyle,
  isCompact = false,
}: UpdatingProps) => {
  if (updating) {
    return (
      <PlainView
        style={[
          { position: 'absolute', right: isCompact ? -25 : -50, top: 15 },
          viewStyle,
        ]}>
        <ActivityIndicator
          size={isCompact ? 'small' : 'large'}
          color={WaffleOrange}
          animating={updating}
        />
      </PlainView>
    );
  }

  return null;
};
