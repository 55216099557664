import React, { useState } from 'react';
import { Policies } from '../../../component-config';
import { VerticalCenter } from '../../Components/SimpleLayouts';
import WaffleText from '../../Components/WaffleText';
import { WaffleOrange } from '../../Constants/Style';
import { Spacer } from '../../Components/SimpleComponents';
import { WaffleButtonAsync } from '../../Components/WaffleButtonAsync';
import {
  isFullWeb,
  IsMidScreen,
  isNativeOrMobileWeb,
} from '../../Helper/DeviceHelper';
import { IsRentalPolicy } from '../../Helper/PolicyHelper';
import { PRODUCT_TYPE_OPTION_LIABILITY_AND_CONTENTS } from '../RentersQuoteScreen/utils/rental-coverage.constants';
import { getRentalCoverages } from '../RentersQuoteScreen/utils/get-rental-coverages';
import { PolicyDescription } from '../../Components/YourPolicy/PolicyDetail';
import { dollarMaybe } from '../../Helper/MiscFunctions';
import { ScrollBox } from '../../Components/ScrollBox';
import { CheckSquare } from '../../Components/CheckSquare';
import { HorizontalPacked } from '../../Components/HorizontalPacked';
import { Platform } from 'react-native';

type Props = {
  policies: Policies;
  onContinue: () => Promise<void>;
};

const CoverageNameValue = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => (
  <WaffleText>
    <WaffleText style={{ fontWeight: '700' }}>{label}</WaffleText> {value}
  </WaffleText>
);

function RentersPolicy({ policies, onContinue }: Props) {
  const height = isFullWeb() ? 420 : IsMidScreen() ? 175 : 300; // maybe this should be shorter?  or more dynamic?
  const width = isFullWeb() ? 720 : 300;
  const policy = policies.find(IsRentalPolicy);
  const coverage = getRentalCoverages(policy.coverage);
  const [disabled, setDisabled] = useState(true);

  return (
    <VerticalCenter>
      <WaffleText
        style={{
          fontSize: IsMidScreen() ? 30 : 36,
          fontWeight: '700',
          textAlign: 'center',
        }}>
        Renters{'\n'}Terms &amp; Conditions
      </WaffleText>

      <Spacer y={3} />

      <CoverageNameValue
        label={'Type of policy:'}
        value={coverage.productCoverage.coverageLimit}
      />
      <CoverageNameValue
        label={'Liability Coverage Limit:'}
        value={dollarMaybe(coverage.liabilityCoverage.coverageLimit)}
      />
      {coverage.productCoverage.coverageLimit ===
        PRODUCT_TYPE_OPTION_LIABILITY_AND_CONTENTS && (
        <>
          <CoverageNameValue
            label={'Content Coverage Deductible:'}
            value={dollarMaybe(
              coverage.contentCoverageDeductible.coverageLimit
            )}
          />
          <CoverageNameValue
            label={'Content Coverage Limit:'}
            value={dollarMaybe(coverage.contentCoverageLimit.coverageLimit)}
          />
        </>
      )}

      <Spacer y={3} />

      <ScrollBox
        width={width}
        height={height}
        onBottom={() => {
          /* no-op */
        }}
        style={{
          borderWidth: 4,
          borderColor: '#FFE8C6',
          borderRadius: 15,
          paddingLeft: 16,
          paddingTop: 16,
          paddingBottom: 16,
          shadowRadius: 5,
          shadowOffset: { width: 0, height: 4 },
          shadowColor: 'rgba(108,62,12,0.5)',
          shadowOpacity: 1,
          elevation: 3,
        }}
        scrollBarStyle={{
          width: 15,
          backgroundColor: WaffleOrange,
          borderRadius: 3,
        }}>
        <PolicyDescription policy={policy} />
      </ScrollBox>

      <Spacer y={3.5} />

      <HorizontalPacked
        style={{
          width: isNativeOrMobileWeb() ? '100%' : undefined,
        }}>
        <CheckSquare
          value={!disabled}
          onValueChanged={() => setDisabled(!disabled)}
        />

        <WaffleText
          style={{
            marginLeft: 12,
            width: Platform.OS === 'ios' ? 280 : '100%',
            fontWeight: 'normal',
            fontSize: 16,
          }}>
          I have read and agree to the renters insurance terms and conditions
        </WaffleText>
      </HorizontalPacked>

      <Spacer y={3.5} />

      <WaffleButtonAsync
        testID={'renters-policy-btn'}
        onPress={onContinue}
        name={'Continue'}
        pressedName={'Continuing...'}
        disabled={disabled}
      />
      <Spacer y={2.5} />
    </VerticalCenter>
  );
}

export { RentersPolicy };
