/* eslint-disable prettier/prettier */
import React, { useEffect, useRef } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import {
  DefaultTheme,
  LinkingOptions,
  NavigationContainer,
  NavigationContainerRef,
} from '@react-navigation/native';
import {
  USER_STATE_ACCOUNT_DETAILS_BEFORE_PAY,
  USER_STATE_CREATE_ACCOUNT_CONTINUE,
  USER_STATE_PAYMENT_INFO,
  USER_STATE_PRODUCT_CHAT,
  USER_STATE_PRODUCT_CHOOSER,
  USER_STATE_SUGGESTED_COVERAGE,
} from './App/Helper/NavigationService';
import HomeScreen from './App/Screens/Home';
import ReferralScreen from './App/Screens/ReferralScreen';
import SettingsScreen from './App/Screens/SettingsScreen';
import ClaimChatScreen from './App/Screens/ClaimChatScreen';
import ManagePlanScreen from './App/Screens/ManagePlanScreen';
import StepMessageScreen from './App/Screens/StepMessageScreen';
import CFMemberCenterScreen from './App/Partners/CFMemberCenterScreen';
import ArchMemberCenterScreen from './App/Partners/ArchMemberCenterScreen';
import ChangePasswordScreen from './App/Screens/ChangePasswordScreen';
import WebScreen from './App/Screens/WebScreen';
import WelcomeScreen from './App/Screens/Welcome';
import LoginScreen from './App/Screens/LoginScreen';
import ForgotPasswordScreen from './App/Screens/ForgotPasswordScreen';
import ResetPasswordScreen from './App/Screens/ResetPasswordScreen';
import CreateAccountScreenCont from './App/Screens/CreateAccountScreenCont';
import Step1Screen from './App/Screens/Step1Screen';
import ProductChooserScreen from './App/Screens/ProductChooserScreen';
import DisclosuresScreen from './App/Screens/DisclosuresScreen';
import ProductChatScreen from './App/Screens/ProductChatScreen';
import YourPolicyScreen from './App/Screens/YourPolicyScreen';
import PaymentInfoScreen from './App/Screens/PaymentInfoScreen/PaymentInfoScreen';
import GiftScreen from './App/Screens/GiftScreen';
import ChubbCompleteScreen from './App/Partners/ChubbCompleteScreen';
import { ChubbFinalizingScreen } from './App/Partners/ChubbFinalizingScreen';
import ChubbPaymentScreen from './App/Partners/ChubbPaymentScreen';
import DailyLifeSaverScreen from './App/Screens/DailyLifeSaverScreen';
import SimpleWebScreen from './App/Screens/SimpleWebScreen';
import LifeBillingScreen from './App/Screens/LifeBillingScreen';
import * as ScreenConstants from './screen-config';
import {
  ConnectToCFScreenName,
  ConnectToHippoScreeName,
  CreateAccountContName,
  CreateLoginScreenName,
  CyberQuoteScreenName,
  HomeScreenName,
  LifeApplicationScreenName,
  LifeQuoteScreenName,
  PaymentInfoScreenName,
  PetQuoteScreenName,
  ProductChatScreenName,
  ProductChooserScreenName,
  PurchaseCompleteScreenName,
  RentersQuoteScreenName,
  RootStackParamList,
  RouteConfig,
  SettingsScreenName,
  StartQuoteScreenName,
  SuggestedCoverageScreenName,
  UpdateGuestUserScreenName,
} from './screen-config';
import LoadingScreen from './App/Screens/LoadingScreen';
import { useAuthContext } from './App/Screens/AuthProvider';
import { Platform, Text } from 'react-native';
import CFMemberCenterPasswordScreen from './App/Partners/CFMemberCenterPasswordScreen';
import { APP_CONFIG, ONBOARDING_STACK_CONFIG } from './header-style';
import SuggestedCoverageScreen from './App/Screens/SuggestedCoverageScreen';
import ConnectToCFScreen from './App/Partners/ConnectToCFScreen';
import ReturnFromCFScreen from './App/Partners/ReturnFromCFScreen';
import EditAccountScreen from './App/Screens/EditAccountScreen';
import StartQuoteScreen from './App/Screens/StartQuoteScreen/StartQuoteScreen';
import { Logger } from './App/Helper/Logger';
import UpdateGuestUserScreen from './App/Screens/UpdateGuestUserScreen';
import CreateLoginScreen from './App/Screens/CreateLoginScreen';
import ConnectToHippoScreen from './App/Partners/ConnectToHippoScreen';
import CyberQuoteScreen from './App/Screens/CyberQuoteScreen';
import BoostAdditionalInterestScreen from './App/Screens/BoostAdditionalInterestScreen';
import RentersQuoteScreen from './App/Screens/RentersQuoteScreen';
import PetQuoteScreen from './App/Screens/PetQuoteScreen';
import PurchaseCompleteScreen from './App/Screens/PurchaseCompleteScreen';
import { useUserTracking } from './lib/user-tracking/UserTrackingProvider';
import LifeQuoteQuestionsScreen from './App/Screens/LifeQuoteQuestionsScreen';
import LifeApplicationScreen from './App/Screens/LifeApplicationScreen/LifeApplicationScreen';
import LifeApplicationDeclinedScreen from './App/Screens/LifeApplicationDeclinedScreen';
import LifeApplicationReviewScreen from './App/Screens/LifeApplicationReviewScreen/LifeApplicationReviewScreen';
import LifeProcessingScreen from './App/Screens/LifeApplicationReviewScreen/LifeProcessingScreen';
import LifeOfferScreen from './App/Screens/LifeOfferScreen';
import LifeRedirectScreen from './App/Screens/LifeRedirectScreen';
import LifeQuoteScreen from './App/Screens/LifeQuoteScreen_Temp/LifeQuoteScreen_temp';

const Stack = createStackNavigator<RootStackParamList>();

const linking: LinkingOptions = {
  prefixes: ['https://dev.trywaffle.net', 'waffleinsurance://'], // xxx
  config: RouteConfig,
};

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'white',
  },
};

function disableIOSFontScaling() {
  (Text as any).defaultProps = (Text as any).defaultProps || {};
  (Text as any).defaultProps.allowFontScaling = false; // Ignore dynamic type scaling on iOS
  // Text.defaultProps.maxFontSizeMultiplier = 1; // this is better way of doing it... eventually...
}

function getLastScreen(userState: string): keyof RootStackParamList {
  switch (userState) {
    case USER_STATE_CREATE_ACCOUNT_CONTINUE:
    case 'EMAIL_CREATED': // deprecate
      return CreateAccountContName;

    case USER_STATE_PRODUCT_CHOOSER:
    case 'INFO_CREATED': // deprecate
      return ProductChooserScreenName;

    case USER_STATE_PRODUCT_CHAT:
      return ProductChatScreenName;

    case USER_STATE_SUGGESTED_COVERAGE:
      return LifeApplicationScreenName;
    case 'CHAT_COMPLETE': // deprecate
      // todo : if policy is cyber, go to cyber screen!
      return SuggestedCoverageScreenName;

    case USER_STATE_PAYMENT_INFO:
    case 'POLICY_SAVED': // deprecate
      return PaymentInfoScreenName;

    case USER_STATE_ACCOUNT_DETAILS_BEFORE_PAY:
      return UpdateGuestUserScreenName;

    // case 'INTERNAL_ONBOARDING_COMPLETE': // deprecate?
    // we need to go to any external onboarding?
    // todo : receive parameter on where we should go?
    // break;
    default:
      return HomeScreenName;
  }
}

const ExitRoutes = new Set([
  ConnectToCFScreenName,
  ConnectToHippoScreeName,
  SettingsScreenName,
]);

// states:
// - pre user
// - guest user
// - auth user

const RootScreen = () => {
  const {
    isLoading,
    token,
    user,
    isGuest,
    initialScreen: initialRestoredScreen,
  } = useAuthContext();
  const navigationRef = useRef<NavigationContainerRef>();
  const routeNameRef = useRef<string>();
  const isGuestOrAuthUser = !!token;
  const isPreUser = !token;
  const isAuthUser = !!token && !isGuest;
  // console.info(`isGuestOrAuth: ${isGuestOrAuthUser}; isPreUser: ${isPreUser}; isAuthUser ${isAuthUser}`)
  const userTracking = useUserTracking();
  const getFirstScreen = (): keyof RootStackParamList => {
    if (isPreUser) {
      return StartQuoteScreenName;
    }
    if (isAuthUser) {
      return HomeScreenName;
    }
  };
  const initialScreen =
    initialRestoredScreen ||
    (user?.userState ? getLastScreen(user?.userState) : getFirstScreen());

  Logger(
    `rootScreen: gOrAU=${isGuestOrAuthUser} preUser=${isPreUser} AU=${isAuthUser} initialScreen=${JSON.stringify(
      initialScreen
    )}`
  );

  const onLeaveApp = (event) => {
    // If route is in a specific set of routes, we don't try to stop the user
    // from leaving the app, as we're trying to do it!
    if (routeNameRef.current && ExitRoutes.has(routeNameRef.current)) {
      return;
    }

    const e = event || window.event;
    // Cancel the event
    e.preventDefault();
    if (e) {
      e.returnValue = ''; // Legacy method for cross browser support
    }
    return ''; // Legacy method for cross browser support
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      window.addEventListener('beforeunload', onLeaveApp);
    }

    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('beforeunload', onLeaveApp);
      }
    };
  }, []);

  useEffect(() => {
    Logger(
      `RootScreen state change: isGuest=${isGuest} token=${!!token} initialScreen=${initialScreen} routeNameRef=${
        routeNameRef.current
      }`
    );
    if (isGuest || !!token) {
      if (initialScreen && initialScreen !== routeNameRef.current) {
        // If we're not guest and we're on the create login screen, that means customer has just come through the first time,
        //   so show them the Purchase Complete / Promo screen.  Otherwise, show the initial screen.
        if (!isGuest && routeNameRef.current === CreateLoginScreenName) {
          navigationRef?.current?.navigate(PurchaseCompleteScreenName);
        } else {
          navigationRef?.current?.navigate(initialScreen);
        }
      }
    }
  }, [isGuest, token]);

  if (isLoading) return <LoadingScreen />;
  if (Platform.OS === 'ios') disableIOSFontScaling();

  return (
    <NavigationContainer
      theme={MyTheme}
      ref={navigationRef}
      linking={linking}
      onReady={() => {
        routeNameRef.current = navigationRef.current.getCurrentRoute().name;
        console.log('on ready routeNameRef' + routeNameRef.current);
        userTracking?.viewedScreenOrPage(routeNameRef.current);
      }}
      onStateChange={() => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.current.getCurrentRoute().name;
        console.info(`on statechange always: ${currentRouteName}`);
        console.info(
          `on statechange always: ${JSON.stringify(
            navigationRef.current.getCurrentRoute()
          )}`
        );

        if (previousRouteName !== currentRouteName) {
          // The line below uses the expo-firebase-analytics tracker
          // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
          // Change this line to use another Mobile analytics SDK
          userTracking?.viewedScreenOrPage(currentRouteName);
          if (
            currentRouteName === CyberQuoteScreenName ||
            currentRouteName === PetQuoteScreenName ||
            currentRouteName === RentersQuoteScreenName ||
            currentRouteName === LifeQuoteScreenName
          ) {
            userTracking?.publishProductViewed(currentRouteName);
          }
        }

        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName;
      }}>
      <Stack.Navigator
        {...ONBOARDING_STACK_CONFIG}
        initialRouteName={initialScreen}>
        {isPreUser && (
          <>
            <Stack.Screen
              name={ScreenConstants.StartQuoteScreenName}
              component={StartQuoteScreen}
            />
            <Stack.Screen
              name={ScreenConstants.WelcomeScreenName}
              component={WelcomeScreen}
            />
            <Stack.Screen
              name={ScreenConstants.LoginScreenName}
              component={LoginScreen}
            />
            <Stack.Screen
              name={ScreenConstants.ForgotPasswordScreenName}
              component={ForgotPasswordScreen}
            />
            <Stack.Screen
              name={ScreenConstants.ResetPasswordScreenName}
              component={ResetPasswordScreen}
            />
          </>
        )}

        {isAuthUser && (
          <>
            <Stack.Screen
              name={ScreenConstants.HomeScreenName}
              component={HomeScreen}
              options={APP_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.ArchMemberCenterScreenName}
              component={ArchMemberCenterScreen}
              options={APP_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.BoostAdditionalInterestScreenName}
              component={BoostAdditionalInterestScreen}
              options={APP_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.CFMemberCenterScreenName}
              component={CFMemberCenterScreen}
              options={APP_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.ChangePasswordScreenName}
              component={ChangePasswordScreen}
              options={APP_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.ClaimChatScreenName}
              component={ClaimChatScreen}
              options={APP_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.DailyLifeSaverScreenName}
              component={DailyLifeSaverScreen}
              options={APP_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.EditAccountScreenName}
              component={EditAccountScreen}
              options={APP_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.GiftScreenName}
              component={GiftScreen}
              options={APP_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.ManagePlanScreenName}
              component={ManagePlanScreen}
              options={APP_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.PurchaseCompleteScreenName}
              component={PurchaseCompleteScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.ReferralScreenName}
              component={ReferralScreen}
              options={APP_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.ReturnFromCFScreenName}
              component={ReturnFromCFScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.StepMessageScreenName}
              component={StepMessageScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
          </>
        )}

        {isGuestOrAuthUser && (
          <>
            <Stack.Screen
              name={ScreenConstants.ProductChooserScreenName}
              component={ProductChooserScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.ChubbCompleteScreenName}
              component={ChubbCompleteScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.ChubFinalizingScreenName}
              component={ChubbFinalizingScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.ChubbPaymentScreenName}
              component={ChubbPaymentScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.CFMemberCenterPasswordScreenName}
              component={CFMemberCenterPasswordScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.ConnectToCFScreenName}
              component={ConnectToCFScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.ConnectToHippoScreeName}
              component={ConnectToHippoScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.CreateAccountContName} // deprecate?
              component={CreateAccountScreenCont}
            />
            <Stack.Screen
              name={ScreenConstants.CyberQuoteScreenName}
              component={CyberQuoteScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.RentersQuoteScreenName}
              component={RentersQuoteScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.DisclosuresScreenName}
              component={DisclosuresScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.LifeQuoteQuestionsScreenName}
              component={LifeQuoteQuestionsScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.LifeQuoteScreenName}
              component={LifeQuoteScreen}
              options={ONBOARDING_STACK_CONFIG}
            />

            <Stack.Screen
              name={ScreenConstants.LifeApplicationDeclined}
              component={LifeApplicationDeclinedScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.LifeApplicationScreenName}
              component={LifeApplicationScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.LifeApplicationReviewScreenName}
              component={LifeApplicationReviewScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.LifeApplicationProcessingScreenName}
              component={LifeProcessingScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.LifeBillingScreenName}
              component={LifeBillingScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.LifeOfferScreenName}
              component={LifeOfferScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.LifeRedirectScreenName}
              component={LifeRedirectScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.PetQuoteScreenName}
              component={PetQuoteScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.PaymentInfoScreenName}
              component={PaymentInfoScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.ProductChatScreenName}
              component={ProductChatScreen}
              options={APP_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.SimpleWebScreenName}
              component={SimpleWebScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.SettingsScreenName}
              component={SettingsScreen}
              options={APP_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.Step1ScreenName}
              component={Step1Screen}
              options={ONBOARDING_STACK_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.SuggestedCoverageScreenName}
              component={SuggestedCoverageScreen}
              options={ONBOARDING_STACK_CONFIG}
            />

            <Stack.Screen
              name={ScreenConstants.WebScreenName}
              component={WebScreen} // does this have right access level?
              options={APP_CONFIG}
            />
            <Stack.Screen
              name={ScreenConstants.YourPolicyScreenName}
              component={YourPolicyScreen}
              options={ONBOARDING_STACK_CONFIG}
            />
          </>
        )}

        {isGuest && (
          <>
            <Stack.Screen
              name={ScreenConstants.CreateLoginScreenName}
              component={CreateLoginScreen}
            />
            <Stack.Screen
              name={ScreenConstants.UpdateGuestUserScreenName}
              component={UpdateGuestUserScreen}
            />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default RootScreen;
