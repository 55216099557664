import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../screen-config';
import { DefaultHeaderStyle } from '../header-style';
import styled from 'styled-components/native';
import React, { useLayoutEffect } from 'react';

const HeaderContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const MyImage = styled.Image`
  width: 105px;
  height: 26px;
`;

const WaffleNavigationLogoHeader = () => (
  <HeaderContainer>
    <MyImage source={require('../assets/images/waffle-header-logo.png')} />
  </HeaderContainer>
);

export function useWaffleLogoNavigationHeader(
  navigation: StackNavigationProp<RootStackParamList>,
  title: string,
  headerShown = true
): void {
  useLayoutEffect(() => {
    navigation.setOptions({
      title,
      headerTitle: WaffleNavigationLogoHeader,
      headerRight: null,
      headerLeft: null,
      headerShown,
      headerStyle: {
        ...DefaultHeaderStyle,
      },
      gestureEnabled: false,
    });
  }, [navigation]);
}
