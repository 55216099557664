import 'reflect-metadata';
import * as React from 'react';
import { FullScreenContent } from './Components/SimpleComponents';
import RootScreen from '../RootScreen';
import { AuthProvider } from './Screens/AuthProvider';
import GraphQLProvider from './GraphQLProvider';
import PaymentsProvider from './PaymentsProvider';
import { BootstrapProvider } from '../lib/bootstrapper/BootstrapProvider';

function App(): React.ReactElement {
  return (
    <BootstrapProvider>
      <GraphQLProvider>
        <PaymentsProvider>
          <AuthProvider>
            <FullScreenContent>
              <RootScreen />
            </FullScreenContent>
          </AuthProvider>
        </PaymentsProvider>
      </GraphQLProvider>
    </BootstrapProvider>
  );
}

export default App;
