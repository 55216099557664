import React, { useState } from 'react';
import { Policies } from '../../../component-config';
import {
  ContentView,
  DataRow,
  Spacer,
  ThickPadding,
} from '../../Components/SimpleComponents';
import { VerticalCenter } from '../../Components/VerticalCenter';
import { TotalBillablePolicies } from '../../Components/TotalBillable';
import { WaffleButtonAsync } from '../../Components/WaffleButtonAsync';
import styles from '../../Constants/Style';
import { PolicyDetail } from '../../Components/YourPolicy/PolicyDetail';
import styled from 'styled-components/native';
import WaffleText from '../../Components/WaffleText';
import {
  GetProducts,
  GetProducts_products,
} from '../../../../operation-result-types';
import { HasConsentPolicy, HasOnceBilling } from '../../Helper/PolicyHelper';

type Props = {
  policies: Policies;
  dataProducts: GetProducts;
  onContinue: () => Promise<void>;
};

const MonthlyLabel = styled(WaffleText)`
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
`;

type PolicyDetailListProps = {
  policies: Policies;
  products: GetProducts_products[];
  consent: boolean;
  setConsent: (boolean) => void;
};

const PolicyDetailList = ({
  policies,
  products,
  consent,
  setConsent,
}: PolicyDetailListProps) => (
  <>
    {policies.map((policy, idx) => (
      <ContentView key={policy.id} style={styles.fullWidth}>
        {idx > 0 && <ThickPadding />}

        <PolicyDetail
          policy={policy}
          product={products.filter((p) => p.id === policy.productId)[0]}
          consent={consent}
          setConsent={setConsent}
        />
      </ContentView>
    ))}
  </>
);

function GenericPolicy({ policies, dataProducts, onContinue }: Props) {
  const needsConsent = HasConsentPolicy(policies);
  const hasOnceBilling = HasOnceBilling(policies);
  const [consent, setConsent] = useState<boolean>(false);
  const disabled = needsConsent && !consent;

  return (
    <>
      <PolicyDetailList
        policies={policies}
        products={dataProducts?.products}
        consent={consent}
        setConsent={setConsent}
      />

      <DataRow style={{ height: 200, width: '100%' }}>
        <VerticalCenter
          style={{
            paddingTop: 30,
            backgroundColor: 'rgba(246,246,246,0.9)',
          }}>
          <MonthlyLabel>Total{!hasOnceBilling && ' Monthly'}</MonthlyLabel>

          <TotalBillablePolicies policies={policies} />

          <Spacer y={2.5} />

          <WaffleButtonAsync
            testID="policy-submit-button"
            onPress={onContinue}
            name={'Enroll Now'}
            pressedName={'Saving...'}
            disabled={disabled}
          />

          <Spacer y={2.5} />
        </VerticalCenter>
      </DataRow>
    </>
  );
}

export { GenericPolicy };
