import React from 'react';
import { NavigationHelpers, ParamListBase } from '@react-navigation/native';
import { MaterialTopTabNavigationEventMap } from '@react-navigation/material-top-tabs/lib/typescript/src/types';

const TabNavigationContext =
  React.createContext<
    NavigationHelpers<ParamListBase, MaterialTopTabNavigationEventMap>
  >(null);

export const useTabNavigation = () => React.useContext(TabNavigationContext);

type Props = {
  navigation: NavigationHelpers<
    ParamListBase,
    MaterialTopTabNavigationEventMap
  >;
  children: React.ReactNode;
};

export const TabNavigationProvider = ({ navigation, children }: Props) => (
  <TabNavigationContext.Provider value={navigation}>
    {children}
  </TabNavigationContext.Provider>
);
