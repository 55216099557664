import { useQuery } from '@apollo/client';
import React, { useLayoutEffect } from 'react';
import {
  HasCyberPolicy,
  HasPetPolicy,
  HasRentersPolicy,
  OnlyRentersPolicies,
} from '../../Helper/PolicyHelper';
import { NavigationBack, Spacer } from '../../Components/SimpleComponents';
import { LoadingUnified } from '../../Components/LoadingUnified';
import { ContentUnified, ScrollingBlock } from '../../Components/ContentShared';
import { StackScreenProps } from '@react-navigation/stack';
import {
  ChubbPaymentScreenName,
  CyberQuoteScreenName,
  PaymentInfoScreenName,
  PetQuoteScreenName,
  RentersQuoteScreenName,
  RootStackParamList,
  SuggestedCoverageScreenName,
} from '../../../screen-config';
import { DRAFT_POLICIES, GET_PRODUCTS } from '../../GraphQL/Waffle/Queries';
import {
  GetDraftUserPolicies,
  GetProducts,
  GetProductsVariables,
} from '../../../../operation-result-types';
import { Policies } from '../../../component-config';
import { isMobileWeb, isNativeOrMobileWeb } from '../../Helper/DeviceHelper';
import { useScrollToTop } from '@react-navigation/native';
import { ScrollView } from 'react-native';
import { BlueHeaderImage } from '../../Components/HeaderNavTitle.web';
import { RentersPolicy } from './RentersPolicy';
import { GenericPolicy } from './GenericPolicy';
import { BuildVersionAndPlatform } from '../../Helper/UserHelpers';

// YourPolicyScreen - view policy before taking payment

const title = 'Your Policy';

type Props = {
  policies: Policies;
  dataProducts: GetProducts;
  onContinue: () => Promise<void>;
};

function YourPolicyBody({ policies, dataProducts, onContinue }: Props) {
  const onlyRenters = OnlyRentersPolicies(policies);

  if (onlyRenters) {
    return <RentersPolicy policies={policies} onContinue={onContinue} />;
  } else {
    return (
      <GenericPolicy
        policies={policies}
        dataProducts={dataProducts}
        onContinue={onContinue}
      />
    );
  }
}

const backTarget = (policies: Policies) => {
  if (HasCyberPolicy(policies)) {
    return CyberQuoteScreenName;
  }

  if (HasPetPolicy(policies)) {
    return PetQuoteScreenName;
  }

  if (HasRentersPolicy(policies)) {
    return RentersQuoteScreenName;
  }

  return SuggestedCoverageScreenName;
};

const YourPolicyScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Your Policy'>) => {
  const ref = React.useRef<ScrollView>(null);
  const { data, loading, error } = useQuery<GetDraftUserPolicies>(
    DRAFT_POLICIES,
    {
      fetchPolicy: 'network-only',
    }
  );
  const {
    loading: loadingProducts,
    error: errorProducts,
    data: dataProducts,
  } = useQuery<GetProducts, GetProductsVariables>(GET_PRODUCTS, {
    variables: BuildVersionAndPlatform(),
  });
  const policies = data?.draftUserPolicies ?? [];
  const hasCyber = HasCyberPolicy(policies);
  const prevScreen = backTarget(policies);

  useScrollToTop(ref);

  useLayoutEffect(() => {
    navigation.setOptions({
      title,
      headerLeft: () => <NavigationBack routeName={prevScreen} />,
      headerShown: isNativeOrMobileWeb(),
      gestureEnabled: true,
    });
  }, [navigation, prevScreen]);

  if (loading || error || loadingProducts || errorProducts) {
    return (
      <LoadingUnified
        title={title}
        errors={error?.message}
        addingProduct={true}
      />
    );
  }

  const onContinue = async () => {
    const target = hasCyber ? ChubbPaymentScreenName : PaymentInfoScreenName;
    console.info('your policy continue: ' + target);
    navigation.navigate(target);
  };

  return (
    <ContentUnified
      title={title}
      image={BlueHeaderImage}
      ChildrenWrapper={ScrollingBlock}
      contentStyle={{ backgroundColor: 'white' }} // This is temporary until this screen gets redesigned
      addingProduct={true}>
      <YourPolicyBody
        policies={policies}
        dataProducts={dataProducts}
        onContinue={onContinue}
      />
      {isMobileWeb() && <Spacer y={7} />}
    </ContentUnified>
  );
};

export default YourPolicyScreen;
